<template>
  <div>
    <Header />

    <!--Beneficios-->
    <div
      class="DivFull_LeftCenter Beneficios Beneficios_FiltroAzul"
      id="Beneficios"
    >
      <div class="container-fluid ContainterFluidMenor_FiltroAzul">
        <div class="col-md-7">
          <h1 class="TituloCor3">Filtro Azul</h1>

          <p class="Black top20 text-justify">
            Os smartphones, tablets, tvs lcd, telas de computador e lâmpadas
            led, emitem altos níveis de luz azul e ficar muito tempo com os
            olhos expostos a esta luz pode causar desconforto visual, fadiga
            ocular e dores de cabeça, e com o tempo outras doenças mais graves.
          </p>

          <p class="Black top10 text-justify">
            As lentes com Filtro Azul bloqueiam apenas os raios nocivos aos
            olhos, proporcionando maior conforto visual mesmo após várias horas
            com a visão focada diretamente à luz.
          </p>

          <div class="DivEscolhas top30">
          <!-- CODE BROKEN
            <div class="row top20">
              <a
                @click="Show_BlueFilter()"
                href="javascript:void(0)"
                class="BtnEmLinha1 BackgroundColor2 TextoBtnCor2"
                id="BtnShowFiltroAzul"
                >COMO FUNCIONA O FILTRO AZUL</a
              >
            </div>
-->
            <div class="row top5">
              <a
                @click="Show_Data()"
                href="javascript:void(0)"
                class="BtnEmLinha1 BackgroundColor2 TextoBtnCor2"
                id="BtnShowPesquisa"
                >DADOS RELEVANTES</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Beneficios-->

    <div class="FiltroAzul_Conteudo" id="Conteudo">
      <a
        @click="Close_BlueFilter()"
        href="javascript:void(0)"
        id="BtnFecharFiltroAzul2"
      >
        <div class="BtnClosePesquisa">
          <img src="./svg/CloseBeneficios.svg" class="" alt="FECHAR" />
        </div>
      </a>

      <div class="container-fluid">
        <!--Slides-->
        <div class="col-md-12">
          <div id="FiltroAzulImagens" class="owl-carousel owl-theme">
            <div class="item" data-hash="zero">
              <img
                src="./images/FiltroAzul/FiltroAzul_Slide1.png"
                class="img-fluid"
                alt="FiltroAzul"
              />
            </div>

            <div class="item" data-hash="one">
              <img
                src="./images/FiltroAzul/FiltroAzul_Slide2.png"
                class="img-fluid"
                alt="FiltroAzul"
              />
            </div>

            <div class="item" data-hash="two">
              <img
                src="./images/FiltroAzul/FiltroAzul_Slide3.png"
                class="img-fluid"
                alt="FiltroAzul"
              />
            </div>
          </div>
        </div>

        <div class="col-md-12 top20 text-center">
          <a
            href="/static/consultor/#zero"
            class="BtnHash BtnSemFiltro"
            id="BtnSemFiltro"
            >SEM FILTRO AZUL</a
          >

          <a
            href="/static/consultor/#one"
            class="BtnHash BtnComFiltro"
            id="BtnComFiltro"
            >COM FILTRO AZUL</a
          >

          <a href="/static/consultor/#two" class="BtnHash BtnLuz" id="BtnLuz"
            >ENTENDENDO A LUZ</a
          >
        </div>
        <!--Slides-->
      </div>
    </div>

    <div class="FiltroAzul_Conteudo Pesquisa" id="Pesquisa">
      <a @click="Close_Data()" href="javascript:void(0)" id="BtnFechaPesquisa">
        <div class="BtnClosePesquisa">
          <img src="./svg/CloseBeneficios.svg" class="" alt="FECHAR" />
        </div>
      </a>

      <div class="container-fluid">
        <!--Slides-->
        <div class="col-md-12">
            <carousel
              :autoplay="false"
              :loop="false"
              :nav="true"
              :dots= "true"
              :responsive="{0:{items:1,nav:false},450:{items:1,nav:true},1000:{items:1,nav:true}}"       
            >
              <img
                src="./images/FiltroAzul/FiltroAzul_Pesquisa1.png"
                class="img-responsive"
                alt="FiltroAzul"
              />
              <img
                src="./images/FiltroAzul/FiltroAzul_Pesquisa2.png"
                class="img-responsive"
                alt="FiltroAzul"
              />
            </carousel>
          </div>
        <!--Slides-->
      </div>
    </div>

    <div class="FiltroAzul_Overlay" id="FiltroAzul_Overlay"></div>

    <div class="DivFull_CenterCenter FiltroAzul" id="SemLuz"></div>
  </div>
</template>
<script>
import Header from '@/views/menu/components/header.vue'
import {
  Show_Data,
  Close_Data,
  Show_BlueFilter,
  Close_BlueFilter,
} from '@/views/menu/js/new/BlueFilter_Script'

import carousel from 'vue-owl-carousel'


export default {
  methods: {
    Show_Data,
    Close_Data,
    Show_BlueFilter,
    Close_BlueFilter,
  },
  components: {
    Header,
    carousel
  },
}
</script>
<style scoped>
@import './css/Style.css';
@import './css/MyStyle.css';
@import './css/MyLogin.css';
@import './css/MyColors.css';
@import './css/MyMenu.css';
@import './css/MyMenuInterno.css';
@import './css/plugins/animate.css';
@import './css/plugins/hamburger.css';
@import './css/plugins/owl.theme.default.min.css';
@import './css/FiltroAzul_Style.css';
</style>
