export function Show_Data() {
  document
    .getElementById('FiltroAzul_Overlay')
    .classList.add('ShowOverlay_FiltroAzul')

  setTimeout(function () {
    document.getElementById('Pesquisa').classList.add('ShowConteudo_FiltroAzul')
  }, 400)
}

export function Close_Data() {
  document
    .getElementById('Pesquisa')
    .classList.remove('ShowConteudo_FiltroAzul')

  setTimeout(function () {
    document
      .getElementById('FiltroAzul_Overlay')
      .classList.remove('ShowOverlay_FiltroAzul')
  }, 400)
}

export function Show_BlueFilter() {
  document
    .getElementById('FiltroAzul_Overlay')
    .classList.add('ShowOverlay_FiltroAzul')

  setTimeout(function () {
    document.getElementById('Conteudo').classList.add('ShowConteudo_FiltroAzul')
    document.getElementById('BtnSemFiltro').classList.add('BtnHash_Inativo')
  }, 400)
}

export function Close_BlueFilter() {
  document
    .getElementById('Conteudo')
    .classList.remove('ShowConteudo_FiltroAzul')

  setTimeout(function () {
    document
      .getElementById('FiltroAzul_Overlay')
      .classList.remove('ShowOverlay_FiltroAzul')

    document.getElementById('BtnComFiltro').classList.remove('BtnHash_Inativo')

    document.getElementById('BtnLuz').classList.remove('BtnHash_Inativo')
  }, 800)
}
